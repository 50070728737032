import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { UserType } from 'src/app/common/enums/user-type';
import { FixedResourceData } from 'src/app/model/fixed-resource';
import { FixedWorkstation } from 'src/app/model/fixed-workstation';
import { FixedParkingSlot } from 'src/app/model/parking';
import { FixedWorkstationApiService } from 'src/app/services/api/fixed-workstation-api.service';
import { ParkingApiService } from 'src/app/services/api/parking-api.service';
import { Booking } from '../../model/booking';
import { OrganizationModules } from '../../model/organization';
import { User } from '../../model/user';
import { WorkingStatus } from '../../model/working-status';
import { ContactsApiService } from '../../services/api/contacts-api.service';
import { UserApiService } from '../../services/api/user-api.service';
import { NotificationService } from '../../services/pages/notification.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: [ './user-info.component.scss' ],
})
export class UserInfoComponent implements OnInit {
  @Input() public user: User | null = null;

  public isFavorite: boolean | null = null;
  public togglingFavorite = false;
  public bookings: readonly Booking[] | null = null;
  public workingStatus: WorkingStatus | null = null;
  public employeeSearch = false;
  public modules = new OrganizationModules();
  public fixedResources$!: Observable<FixedResourceData[]>;
  public now = new Date();

  public constructor(
    private contactsApi: ContactsApiService,
    private userApi: UserApiService,
    private fixedWorkstationApi: FixedWorkstationApiService,
    private parkingApi: ParkingApiService,
    private notificationService: NotificationService,
    private clipboard: Clipboard,
  ) {}

  public ngOnInit(): void {
    if (this.user === null) {
      return;
    }
    const { user } = this;
    this.contactsApi.isFavorite(user).then(isFavorite => {
      this.isFavorite = isFavorite;
    });
    this.userApi.getUser().subscribe(userMe => {
      const modules = userMe.organization?.modules;
      if (modules) {
        this.modules = modules;
        this.employeeSearch = modules.employeeSearch && user.type === UserType.INTERNAL;
        this.contactsApi.getUserBookings(user).then(userMoreInfo => {
          user.populate(userMoreInfo);
          this.workingStatus = user.userWorkingStatus;
          this.bookings = userMoreInfo.nextBookingsInfo;
        });
      }
    });
    this.setFixedResources(user);
  }

  public async toggleFavorite(): Promise<void> {
    if (this.user === null) {
      return;
    }
    try {
      this.togglingFavorite = true;
      if (this.isFavorite) {
        await this.contactsApi.removeFavorite(this.user);
      } else {
        await this.contactsApi.addFavorite(this.user);
      }
      this.isFavorite = !this.isFavorite;
      this.notificationService.success(
        this.isFavorite ? 'bkwa.profile.favorites.addToFavorites' : 'bkwa.profile.favorites.removedFromFavorites',
        true,
      );
    } finally {
      this.togglingFavorite = false;
    }
  }

  public copyToClipboard(data: string): void {
    if (this.clipboard.copy(data)) {
      this.notificationService.success('bkwa.spacecard.videoconference.copySuccess', true);
    }
  }

  private setFixedResources(user: User) {
    this.fixedResources$ = forkJoin([ this.fixedWorkstationApi.getFixedWorkstation(user), this.parkingApi.getFixedParkingSlot(user) ]).pipe(
      map(fixedResources =>
        fixedResources
          .filter((fixedResource): fixedResource is FixedWorkstation | FixedParkingSlot => fixedResource != null)
          .map(fixedResource => new FixedResourceData(fixedResource))
          .sort((aFixedResource, bFixedResource) => aFixedResource.fixedResource.compare(bFixedResource.fixedResource)),
      ),
    );
  }
}
