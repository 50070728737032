@if (user != null) {
  <article class="profile">
    <div class="d-md-flex justify-content-between align-items-center">
      <div class="avatar-container mb-3">
        <app-avatar [user]="user">
          @if (modules.workingStatusActivated && workingStatus != null) {
            <p class="status extra-append">
              <span [matTooltip]="'bkwa.profile.statusTooltip' | translate">{{ workingStatus.getName() }}</span>
            </p>
          }
        </app-avatar>
        @if (isFavorite != null) {
          <button
            mat-icon-button
            (click)="toggleFavorite()"
            [disabled]="togglingFavorite"
            [matTooltip]="(isFavorite ? 'bkwa.profile.favorites.tooltipRemove' : 'bkwa.profile.favorites.tooltipAdd') | translate"
          >
            <mat-icon [class.is-favorite]="isFavorite" [fontSet]="isFavorite ? 'filled' : ''">star</mat-icon>
          </button>
        }
      </div>
      <ng-content></ng-content>
    </div>
  </article>
  @if (user.email != null) {
    <article>
      <mat-list class="mat-mdc-action-list">
        <mat-list-item class="email">
          <a href="mailto:{{ user.email }}">
            <mat-icon class="align-bottom" [matTooltip]="'bkwa.profile.emailTooltip' | translate">mail</mat-icon>
            {{ user.email }}
          </a>
          <button
            mat-button
            color="primary"
            class="normal-case me-n2"
            [matTooltip]="'bkwa.spacecard.videoconference.copyTooltip' | translate"
            (click)="copyToClipboard(user.email)"
          >
            {{ 'bkwa.spacecard.videoconference.copy' | translate }}
          </button>
        </mat-list-item>
      </mat-list>
    </article>
  }
  @if (employeeSearch) {
    <article>
      <mat-tab-group mat-stretch-tabs dynamicHeight>
        <mat-tab [label]="'bkwa.profile.bookings.title' | translate">
          <mat-list>
            @if (!user.canBeSearched && !modules.employeeSearchMandatory) {
              <mat-list-item>
                <div class="empty w-100 mt-4">
                  <img src="assets/images/empty-case-attendees.svg" class="img-fluid" [alt]="'bkwa.profile.bookings.cannotBeSearched' | translate" />
                  {{ 'bkwa.profile.bookings.cannotBeSearched' | translate }}
                </div>
              </mat-list-item>
            } @else {
              @if (bookings == null || bookings.length === 0) {
                <mat-list-item>
                  <div class="empty w-100 mt-4">
                    <img src="assets/images/not-found-icon.svg" class="img-fluid" [alt]="'bkwa.profile.bookings.notBookingToShow' | translate" />
                    {{ 'bkwa.profile.bookings.notBookingToShow' | translate }}
                  </div>
                </mat-list-item>
              } @else {
                @for (booking of bookings; track booking) {
                  <mat-list-item class="card">
                    @if (booking.resource != null) {
                      <app-resource [resource]="booking.resource">
                        <span>
                          <mat-icon [matTooltip]="'bkwa.card.timeTooltip' | translate">schedule</mat-icon>
                          {{ booking.startDate | i18nDate: 'E d MMM' | firstCapitalize }},
                          @if (booking.allDayBooking) {
                            {{ 'bkwa.common.all-day' | translate | lowercase }}
                          } @else {
                            {{ booking | timeFromTo: booking.resource.floor?.building }}
                          }
                        </span>
                      </app-resource>
                    }
                  </mat-list-item>
                }
              }
            }
          </mat-list>
        </mat-tab>
        <mat-tab [label]="'bkwa.profile.fixedResources.title' | translate">
          <ng-template matTabContent>
            <mat-list>
              @if (!user.canBeSearched && !modules.employeeSearchMandatory) {
                <mat-list-item>
                  <div class="empty w-100 mt-4">
                    <img
                      src="assets/images/empty-case-attendees.svg"
                      class="img-fluid"
                      [alt]="'bkwa.profile.fixedResources.cannotBeSearched' | translate"
                    />
                    {{ 'bkwa.profile.fixedResources.cannotBeSearched' | translate }}
                  </div>
                </mat-list-item>
              } @else {
                @if (fixedResources$ | async; as fixedResources) {
                  @if (fixedResources.length === 0) {
                    <mat-list-item>
                      <div class="empty w-100 mt-4">
                        <img
                          src="assets/images/not-found-icon.svg"
                          class="img-fluid"
                          [alt]="'bkwa.profile.fixedResources.notBookingToShow' | translate"
                        />
                        {{ 'bkwa.profile.fixedResources.notBookingToShow' | translate }}
                      </div>
                    </mat-list-item>
                  } @else {
                    @for (fixedResource of fixedResources; track fixedResource) {
                      <mat-list-item class="card">
                        @if (fixedResource.resource != null) {
                          <app-resource [resource]="fixedResource.resource">
                            <span>
                              <mat-icon [matTooltip]="'bkwa.card.timeTooltip' | translate">schedule</mat-icon>
                              @if (fixedResource.fixedResource.isAllDay()) {
                                {{ 'bkwa.common.all-day' | translate | lowercase }}
                              } @else {
                                {{ fixedResource.fixedResource.getStartTime(now) | time: fixedResource.resource.floor?.building }} -
                                {{ fixedResource.fixedResource.getEndTime(now) | time: fixedResource.resource.floor?.building }}
                              }
                            </span>
                          </app-resource>
                        }
                      </mat-list-item>
                    }
                  }
                } @else {
                  <div class="d-flex justify-content-center my-3">
                    <mat-progress-spinner mode="indeterminate" />
                  </div>
                }
              }
            </mat-list>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </article>
  }
}
