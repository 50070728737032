import { NgModule } from '@angular/core';
import { UserInfoComponent } from './user-info.component';
import { SharedModule } from '../../shared.module';
import { AvatarModule } from '../avatar/avatar.module';
import { AlertModule } from '../alert/alert.module';
import { ResourceModule } from '../resource/resource.module';


@NgModule({
  declarations: [
    UserInfoComponent,
  ],
  exports: [
    UserInfoComponent,
  ],
  imports: [
    SharedModule,
    AvatarModule,
    AlertModule,
    ResourceModule,
  ],
})
export class UserInfoModule {
}
